import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Amplify } from 'aws-amplify';
import { AmplifyAuthenticatorModule, AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FavPropertiesComponent } from '../fav-properties/fav-properties.component';
import { AppServiceService } from '../services/app-service.service';
import { environment } from '../../env/environment';

import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, getCurrentUser, AuthUser } from "aws-amplify/auth";


// Amplify
// https://resonant-cement-f3c.notion.site/Implementing-Amazon-Cognito-Authentication-in-Angular-using-AWS-Amplify-dc88cce964d34fb0b41417d76d61efe0
// https://ui.docs.amplify.aws/angular/connected-components/authenticator


// DynamoDB
// https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-dynamo-db.html
// https://github.com/chaotictoejam/AWSTutorials/tree/master

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    AmplifyAuthenticatorModule,
    FavPropertiesComponent,
    CommonModule,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  formFields = {
    signUp: {
      name: {
        order: 1
      },
      email: {
        order: 2
      },
      phone_number: {
        order: 3
      },
      password: {
        order: 5
      },
      confirm_password: {
        order: 6
      }
    },
  };

  // loggedIn: any = false;

  authenticated: boolean = false;
  env = environment;
  userConsented = false;

  services = {
    async validateCustomSignUp(formData: any) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms and Conditions',
        };
      }
      return null;
    },
  };

  constructor(
    private authService: AuthService,
    public appService: AppServiceService,
    public authenticator: AuthenticatorService,
    private router: Router,
  ) {

    // Amplify.configure({
    //   Auth: {
    //     Cognito: {
    //       loginWith: {
    //         oauth: {
    //           domain: "hhamplifybf7f3e6a-bf7f3e6a-dev.auth.us-east-1.amazoncognito.com",
    //           scopes: [
    //             "phone",
    //             "email",
    //             "openid",
    //             "profile",
    //             "aws.cognito.signin.user.admin"
    //           ],
    //           redirectSignIn: ["http://localhost:4200/properties/"],
    //           redirectSignOut: ["http://localhost:4200/login/"],
    //           responseType: "code"
    //         },
    //       },
    //       userPoolId: 'us-east-1_6cFvxxTXj',
    //       userPoolClientId: '6skqf30ubk3kullfg9epr6j5vb'
    //       // userPoolId: 'us-east-1_9HUM2wq2Z',
    //       // userPoolClientId: '6o1lfbd2670ouhamknbd8mvp7k'
    //     }
    //   }
    // });
  }

  async ngOnInit() {
    // this.loggedIn = false;

    // let user = await this.authService.getCurrentUser();
    // console.log(`Current user: ${JSON.stringify(user)}`)

    // let session = await this.authService.getCurrentSession();
    // // console.log(session?.idToken?.toString())

    // if (user.username) {
    //   this.loggedIn = true;
    // }

    // console.log(`AuthenticatorService authenticated: ${JSON.stringify(this.authenticator.user)}`)

    this.authenticated = await this.authService.isAuthenticated();
    if (this.authenticated) {
      this.router.navigate(['my-profile'])
    }
  }

  toConsent(event: any) {
    if (event && event.target) {
      this.userConsented = event.target.checked;
    }
  }


}
