<div class="login-page">
    <!-- <amplify-authenticator [socialProviders]="['google']" [loginMechanisms]="['email']" [formFields]="formFields"
        [signUpAttributes]="['name']" [hideSignUp]="true"> -->
    <amplify-authenticator [services]="services" [loginMechanisms]="['email']" [formFields]="formFields"
        [signUpAttributes]="['name','phone_number']">
        <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">

            <!-- <div class="d-flex justify-content-between align-items-center login-header mb-3">
                <div *ngIf="appService.isMobile" class="login-title">Properties</div>

                <div class="buttons-wrapper">
                    <span>{{ user?.signInDetails?.loginId }}</span>
                    <span *ngIf="!appService.isMobile" class="login-title">Properties</span>
                    <button mat-flat-button (click)="signOut()"
                        class="d-flex justify-content-center button-logout text-uppercase">
                        signout</button>
                </div>
            </div> -->

            <!-- <app-fav-properties></app-fav-properties> -->

            <!-- <h1>Welcome {{ user.username }}!!</h1>
                <button (click)="signOut()">Sign Out</button> -->
        </ng-template>

        <!-- sign-up-field override -->
        <ng-template amplifySlot="sign-up-form-fields" let-validationErrors="validationErrors">
            <!-- Re-use default `Authenticator.SignUp.FormFields` -->
            <amplify-sign-up-form-fields></amplify-sign-up-form-fields>

            <!-- Append & require Terms and Conditions field to sign up -->
            <amplify-checkbox [errorMessage]="validationErrors.acknowledgement"
                [hasError]="!!validationErrors.acknowledgement" name="acknowledgement" value="yes">
                <!-- I agree with the Terms and Conditions. -->
                <div>I agree by providing {{env.clientName}} your contact information, you acknowledge and
                    agree to our
                    <a [routerLink]="'/privacy-policy'">Privacy Policy</a>. This consent isn't necessary for purchasing
                    any services or products. You may opt out at any time.
                </div>
            </amplify-checkbox>
        </ng-template>

        <!-- <ng-template amplifySlot="footer">
            <div style="padding: var(--amplify-space-large); text-align: left">
                <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
                    Terms and Conditions: By providing {{env.clientName}} your contact information, you acknowledge and
                    agree to our
                    <a [routerLink]="'/privacy-policy'">Privacy Policy</a>. This consent isn't necessary for purchasing
                    any services or products. You may opt out at any time.
                </p>
            </div>
        </ng-template> -->
    </amplify-authenticator>

</div>