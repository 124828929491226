<div mat-dialog-content>
    <div class="d-flex flex-column mt-2 mb-3">
        <mat-form-field class="" appearance="fill">
            <mat-label>Email</mat-label>
            <!-- <input matInput [(ngModel)]="email" (input)="userInput('title')" maxLength="{{ max_char_title }}" required> -->
            <input matInput [(ngModel)]="email" required>
            <mat-hint>Eamil</mat-hint>
        </mat-form-field>

        <mat-form-field class="" appearance="fill">
            <mat-label>Temp Password</mat-label>
            <!-- <input matInput [(ngModel)]="tempPassword" (input)="userInput('title')" maxLength="{{ max_char_title }}" required> -->
            <input matInput [(ngModel)]="tempPassword" required>
            <mat-hint>Temp Password</mat-hint>
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center pb-3">
    <button mat-flat-button class="message-ok-button button-default-thin text-uppercase"
        (click)="onNoClick()">Cancel</button>
    <!-- <button mat-flat-button class="ml-2 message-ok-button button-default-thin text-uppercase"
        [mat-dialog-close]="delete" (click)="onOk()" cdkFocusInitial>Add</button> -->
    <button mat-flat-button class="ml-2 message-ok-button button-default-thin text-uppercase"
        (click)="onOk()">Add</button>
</div>