// For /properties Angular route, use /favProperties API Gateway

import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  forwardRef,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, RouterModule } from '@angular/router';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';

import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { Sort, MatSortModule } from '@angular/material/sort';
import { GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquarePlus, faPlusSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth.service';
import { AppServiceService } from '../services/app-service.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';


import { v4 as uuidv4 } from 'uuid';
// import { Md5 } from 'ts-md5'
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_LOADER_OPTIONS,
  RecaptchaLoaderOptions,
} from "ng-recaptcha";
import { uploadData } from '@aws-amplify/storage';
import { WorkOrderComponent } from "../work-order/work-order.component";
import { UserComponent } from '../user/user.component';

@Component({
  selector: 'app-fav-properties',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    forwardRef(() => MyTelInput),
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    GoogleMapsModule,
    TranslateModule,
    RecaptchaModule,
    FontAwesomeModule,
    MatSortModule,
    WorkOrderComponent,
    UserComponent,
  ],
  templateUrl: './fav-properties.component.html',
  styleUrl: './fav-properties.component.scss',
  providers: [
    provideNativeDateAdapter(),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
    },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavPropertiesComponent implements OnInit {

  // DynamoDB design
  // https://medium.com/telemetryhub-dev/dynamodb-single-table-design-d60be6aa2298
  // https://www.youtube.com/watch?v=kQ-DSjtCb90
  // https://www.youtube.com/watch?v=p8phVh6HRkQ
  // https://dynobase.dev/code-examples/dynamodb-query-keyconditionexpression/#:~:text=The%20KeyConditionExpression%20parameter%20is%20used,(Attribute_name%20%3D%20%3Avalue).
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/dynamodb/command/QueryCommand/
  // https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Query.KeyConditionExpressions.html

  ENUMS = ENUMS;

  faSquarePlus = faSquarePlus;
  faPlusSquare = faPlusSquare;
  faTrashCan = faTrashCan;

  session: any;
  user: any;
  url: any;
  properties: any;
  newProperty = false;
  usersUrl: any;

  selectedProperty: any = null;
  emptyProperty: any = {
    customerId: '',
    address: '',
    owner: null,
    community: null,
    hoaName: null,
    hoaMgrName: null,
    hoaCost: null,
    hoaDraftType: null,
    hoaDraftFreq: null,
    hoaPhone: null,
    hoaEmail: null,
    hoa2ndName: null,
    hoa2ndMgrName: null,
    hoa2ndCost: null,
    hoa2ndDraftType: null,
    hoa2ndDraftFreq: null,
    hoa2ndPhone: null,
    hoa2ndEmail: null,
    // repairHistory: null,
    // warranty: null,
    propertyTax: null,
    insurance: null,
    tenantID: null,
    rent: null,
    tenanName: null,
    leaseStart: null,
    leaseEnd: null,
    tenantPhone: null,
    tenantEmail: null,
    pet: null,
    note: null,
    repairs: [],
  };

  repairItem: any = {
    repairItem: null,
    repairCost: null,
    repairDate: null,
    repairNotes: null,
  }

  sortedRepairs: any = [];

  md5: any;
  // contactUsFormGroup: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);

  environment = environment;
  submitted = false;
  valid = false;
  recaptchaValid = false;

  max_char_owner = ENUMS.USER_INPUT_LIMIT_EX_SHORT;
  reached_max_char_owner = false;
  max_char_hoaNameMaster = ENUMS.USER_INPUT_LIMIT_EX_SHORT;
  reached_max_char_hoaNameMaster = false;
  reached_max_char_hoaNameSecond = false;
  max_char_hoaManagerMaster = ENUMS.USER_INPUT_LIMIT_EX_SHORT;
  reached_max_char_hoaManagerMaster = false;
  reached_max_char_hoaManagerSecond = false;
  max_char_hoaCostMaster = ENUMS.USER_INPUT_LIMIT_COST_SMALL;
  max_charLen_hoaCostMaster = ENUMS.USER_INPUT_LIMIT_COST_LENGTH_SMALL;
  reached_max_char_hoaCostMaster = false;
  reached_max_char_hoaCostSecond = false;
  reached_max_char_repairCost = false;
  max_char_hoaEmailMaster = ENUMS.USER_INPUT_LIMIT_EX_SHORT;
  reached_max_char_hoaEmailMaster = false;
  reached_max_char_hoaEmailSecond = false;
  reached_max_char_tenantEmail = false;
  max_char_repairNotes = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_repairNotes = false;
  max_char_propertyTax = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_propertyTax = false;
  reached_max_char_insurance = false;
  max_char_rent = ENUMS.USER_INPUT_LIMIT_COST_SMALL;
  reached_max_char_rent = false;
  max_char_tenantID = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_tenantID = false;
  max_char_tenantName = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_tenantName = false;
  max_char_pet = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_pet = false;
  max_char_notes = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_notes = false;

  file: File | null = null;
  tenantTableUrl: any;
  tenantIDPrevious: any;
  tenantAddressPrevious: any;
  tenantEmailPrevious: any;

  selectedTabIndex = 1;

  subscribersUrl;

  userInput(type: string) {
    switch (type) {
      case 'owner':
        if (this.selectedProperty.owner.length >= this.max_char_owner) {
          this.reached_max_char_owner = true;
        } else {
          this.reached_max_char_owner = false;
        }
        break;
      case 'hoaNameMaster':
        if (this.selectedProperty.hoaName.length >= this.max_char_hoaNameMaster) {
          this.reached_max_char_hoaNameMaster = true;
        } else {
          this.reached_max_char_hoaNameMaster = false;
        }
        break;
      case 'hoaManagerMaster':
        if (this.selectedProperty.hoaMgrName.length >= this.max_char_hoaManagerMaster) {
          this.reached_max_char_hoaManagerMaster = true;
        } else {
          this.reached_max_char_hoaManagerMaster = false;
        }
        break;
      case 'hoaCostMaster':
        if (this.selectedProperty.hoaCost >= this.max_char_hoaCostMaster) {
          setTimeout(() => {
            this.selectedProperty.hoaCost = Number((this.selectedProperty.hoaCost + '').slice(0, Number(this.max_charLen_hoaCostMaster)));
          }, 300);
          this.reached_max_char_hoaCostMaster = true;
        } else {
          this.reached_max_char_hoaCostMaster = false;
        }
        break;
      case 'hoaEmailMaster':
        if (this.selectedProperty.hoaEmail.length >= this.max_char_hoaEmailMaster) {
          this.reached_max_char_hoaEmailMaster = true;
        } else {
          this.reached_max_char_hoaEmailMaster = false;
        }
        break;
      case 'hoaNameSecond':
        if (this.selectedProperty.hoa2ndName.length >= this.max_char_hoaNameMaster) {
          this.reached_max_char_hoaNameSecond = true;
        } else {
          this.reached_max_char_hoaNameSecond = false;
        }
        break;
      case 'hoaManagerSecond':
        if (this.selectedProperty.hoa2ndMgrName.length >= this.max_char_hoaManagerMaster) {
          this.reached_max_char_hoaManagerSecond = true;
        } else {
          this.reached_max_char_hoaManagerSecond = false;
        }
        break;
      case 'hoaCostSecond':
        if (this.selectedProperty.hoa2ndCost >= this.max_char_hoaCostMaster) {
          setTimeout(() => {
            this.selectedProperty.hoa2ndCost = Number((this.selectedProperty.hoa2ndCost + '').slice(0, Number(this.max_charLen_hoaCostMaster)));
          }, 300);
          this.reached_max_char_hoaCostSecond = true;
        } else {
          this.reached_max_char_hoaCostSecond = false;
        }
        break;
      case 'hoaEmailSecond':
        if (this.selectedProperty.hoa2ndEmail.length >= this.max_char_hoaEmailMaster) {
          this.reached_max_char_hoaEmailSecond = true;
        } else {
          this.reached_max_char_hoaEmailSecond = false;
        }
        break;
      case 'repairCost':
        if (this.repairItem.repairCost >= this.max_char_hoaCostMaster) {
          setTimeout(() => {
            this.repairItem.repairCost = Number((this.repairItem.repairCost + '').slice(0, Number(this.max_charLen_hoaCostMaster)));
          }, 300);
          this.reached_max_char_repairCost = true;
        } else {
          this.reached_max_char_repairCost = false;
        }
        break;
      case 'repairNotes':
        if (this.repairItem.repairNotes.length >= this.max_char_repairNotes) {
          this.reached_max_char_repairNotes = true;
        } else {
          this.reached_max_char_repairNotes = false;
        }
        break;
      case 'propertyTax':
        if (this.selectedProperty.propertyTax.length >= this.max_char_propertyTax) {
          this.reached_max_char_propertyTax = true;
        } else {
          this.reached_max_char_propertyTax = false;
        }
        break;
      case 'insurance':
        if (this.selectedProperty.insurance.length >= this.max_char_propertyTax) {
          this.reached_max_char_insurance = true;
        } else {
          this.reached_max_char_insurance = false;
        }
        break;
      case 'tenantID':
        if (this.selectedProperty.tenantID >= this.max_char_tenantID) {
          this.reached_max_char_tenantID = true;
        } else {
          this.reached_max_char_tenantID = false;
        }
        break;
      case 'rent':
        if (this.selectedProperty.rent >= this.max_char_rent) {
          this.reached_max_char_rent = true;
        } else {
          this.reached_max_char_rent = false;
        }
        break;
      case 'tenantName':
        if (this.selectedProperty.tenantName.length >= this.max_char_tenantName) {
          this.reached_max_char_tenantName = true;
        } else {
          this.reached_max_char_tenantName = false;
        }
        break;
      case 'tenantEmail':
        if (this.selectedProperty.tenantEmail.length >= this.max_char_hoaEmailMaster) {
          this.reached_max_char_tenantEmail = true;
        } else {
          this.reached_max_char_tenantEmail = false;
        }
        break;
      case 'pet':
        if (this.selectedProperty.pet.length >= this.max_char_pet) {
          this.reached_max_char_pet = true;
        } else {
          this.reached_max_char_pet = false;
        }
        break;
      case 'notes':
        if (this.selectedProperty.note.length >= this.max_char_notes) {
          this.reached_max_char_notes = true;
        } else {
          this.reached_max_char_notes = false;
        }
        break;
    }
  }

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Access-Control-Allow-Methods', 'OPTIONS,POST')
    // .set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token')
    // .set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    .set('Content-Type', `application/json`);

  constructor(
    public authService: AuthService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private builder: FormBuilder,
    public appService: AppServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.subscribersUrl = `${environment.rootUrl}prod/subscribers/`;

    this.authService.isInGroup(ENUMS.LANDLORD_GROUP).then((auth => {
      let authorized = auth;
      if (!authorized) {

        this.route.queryParams.subscribe(params => {
          this.appService.urlSelectedVendor = params['vendor'];
          this.appService.urlSelectedWoId = params['woId'];
          this.appService.urlSelectedAddress = params['address'];

          if (this.appService.urlSelectedVendor) {
            console.log("This will auto go to /login page, issued from auth check route")
            this.router.navigate(["/login"]);
          } else {
            this.router.navigate(["/"]);
          }
        })
      }
    }))

    // this.url = 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/favProperties/';
    this.url = `${environment.rootUrl}prod/favProperties/`;
    this.tenantTableUrl = `${environment.rootUrl}prod/tenants/`;
    this.usersUrl = `${environment.rootUrl}prod/users/`;


    // this.md5 = new Md5();

    // this.contactUsFormGroup = this.builder.group({
    //   address: ['', [Validators.required]],
    //   rent: ['',],
    //   // firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
    //   // lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
    //   // // email: ['', [Validators.required, Validators.email]],
    //   // phone: ['', [
    //   //   Validators.required,
    //   //   // Validators.pattern("^[0-9]*$"),
    //   //   // Validators.pattern('^\d{3}-\d{3}-\d{4}$'),
    //   //   // Validators.minLength(10),
    //   //   // Validators.maxLength(13)
    //   // ]],
    //   // message: ['', [Validators.required]],
    // })
  }

  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
    // console.log(user)

    this.session = await this.authService.getCurrentSession();
    // console.log(this.session?.idToken?.toString())

    this.getProperties();
  }

  // onFileSelected(event: any) {
  //   const file: File = event.target.files[0];
  //   if (file) {
  //     this.file = file;
  //   }
  // }

  // async uploadFile() {
  //   if (this.file) {
  //     const fileName = this.file.name;
  //     let identityId = 'c4d85418-b031-70ce-9ccc-d0ba94a9868d';
  //     uploadData({
  //       path: `protected/${identityId}/album/2024/${fileName}`,
  //       // path: this.file.name,
  //       // path: `public/album/2024/${fileName}`,
  //       data: this.file
  //     });
  //   }
  // }

  addUser() {
    console.log('add user')

    const dialogRef = this.dialog.open(UserComponent, {
      width: '500px',
      data: { modal: true },
      panelClass: 'view-wo-files-dialog-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        this.selectedProperty.tenantID = result.subId;
        this.selectedProperty.tenantEmail = result.email;
      }
    })
  }

  createNewProperty() {
    this.newProperty = true;
    this.selectedProperty = JSON.parse(JSON.stringify(this.emptyProperty));
  }

  routePropertiesMain() {
    this.selectedProperty = null;
    this.newProperty = false;
  }

  selectProperty(p: any) {
    this.selectedProperty = p;

    if (p.tenantID) {
      this.tenantIDPrevious = p.tenantID;
      this.tenantAddressPrevious = p.address;
      this.tenantEmailPrevious = p.tenantEmail;
    } else {
      this.tenantIDPrevious = null;
      this.tenantAddressPrevious = null;
      this.tenantEmailPrevious = null;
    }

    if (p.repairs) {
      this.sortedRepairs = p.repairs;
    }

    // this.contactUsFormGroup.value.address = p.address;
    // this.contactUsFormGroup.value.rent = p.rent;
  }

  sortPropertyByNumber() {
    this.properties.sort((a: any, b: any) => {
      let aNum = this.displayAddressOnlyNumber(a.address);
      let bNum = this.displayAddressOnlyNumber(b.address);
      return (aNum < bNum ? -1 : 1)
    })
  }

  getProperties() {
    try {
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.get(this.url, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          this.properties = data.body;

          this.sortPropertyByNumber();

          setTimeout(() => {
            this.selectedProperty = null;
            this.newProperty = false;
          }, 300)

          // Test
          // setTimeout(() => {
          //   // this.createNewProperty();
          //   this.selectProperty(this.properties[0]);
          // }, 500)
        },
        (error) => {
          console.log(error);
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  removeEmptyAttributes() {
    let tempProperty = JSON.parse(JSON.stringify(this.selectedProperty));
    let keyArray: any = [];
    Object.values(tempProperty).forEach((v: any, index: number) => {
      if (!v || v.length === 0) {
        keyArray.push(Object.keys(tempProperty)[index]);
      }
    })

    keyArray.forEach((i: any) => {
      delete tempProperty[i];
    })

    this.selectedProperty = JSON.parse(JSON.stringify(tempProperty));
  }

  deleteProperty() {
    this.openDialog();

    // this.url = 'https://v18u4s0da6.execute-api.us-east-1.amazonaws.com/prod/favProperties/' + this.selectedProperty.propertyId;

    // try {
    //   this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    //   this.httpClient.delete(this.url, this.selectedProperty).subscribe(
    //     (data: any) => {
    //       console.log(data)
    //       this.selectedProperty = null;
    //       this.newProperty = false;
    //       this.getProperties()
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }
    // catch (e) {
    //   console.log(e);
    // }
  }

  getSortId() {
    return this.selectedProperty.address.split(' ').join('_') + '_' + (new Date).getTime();
  }

  getErrorMessage() {

    // if (this.contactUsFormGroup.controls['address'].invalid
    // ) {
    //   return 'Please enter only letters.'
    // }

    // if (this.contactUsFormGroup.controls['firstName'].invalid
    //   || this.contactUsFormGroup.controls['lastName'].invalid) {
    //   return 'Please enter only letters.'
    // }

    // return
  }

  getNameErrorMessage() {
    // if (this.contactUsFormGroup.controls['firstName'].invalid
    //   || this.contactUsFormGroup.controls['lastName'].invalid) {
    //   return 'Please enter only letters.'
    // }
    // return
  }

  addToTenantTable() {
    let data = {
      tenantId: this.selectedProperty.tenantID,
      address: this.selectedProperty.address,
      name: this.selectedProperty.tenantName,
      phone: this.selectedProperty.tenantPhone,
      email: this.selectedProperty.tenantEmail,
    }
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.put(this.tenantTableUrl, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log(data)
      }
    )
  }

  removeFromTenantTable(removeTenantGroup = false) {
    let tenantTableUrl = this.tenantTableUrl + `${this.tenantIDPrevious}`;

    let body = {
      tenantAddress: this.tenantAddressPrevious
    }

    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.delete(tenantTableUrl, { headers: this.httpHeaders, observe: 'response', body: body, }).subscribe(
      (data: any) => {
        console.log(data)
      }
    )

    // remove tenant in Cognito TenantGroup role
    if (removeTenantGroup) {
      this.httpClient.delete(this.usersUrl, {
        headers: this.httpHeaders,
        params: {
          username: this.tenantEmailPrevious,
          group: 'TenantGroup'
        }
      }).subscribe(
        (data: any) => {
          console.log(data.body)

        }, (error) => {
          console.log(JSON.stringify(error))
        }
      )
    }
  }

  saveProperty() {

    // this.selectedProperty = {
    //   address: data.address,
    //   rent: data.rent,
    //   // hoaName: data.hoaName,
    //   // hoaPhone: `${data.phone.area}-${data.phone.exchange}-${data.phone.subscriber}`,
    //   // hoaEmail: `${this.email.value}`,
    // }

    if (this.newProperty) {
      // this.selectedProperty.customerId = this.getCustomerId();
      this.selectedProperty.address = this.getSortId();
      this.removeEmptyAttributes();
    }

    this.selectedProperty.tenantID = this.selectedProperty.tenantID.trim();

    try {
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.put(this.url, this.selectedProperty, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          console.log(data)

          let tenantIdCurrent = this.selectedProperty.tenantID;
          if (tenantIdCurrent) {
            // add tenantID
            this.addToTenantTable();
          }

          if (this.tenantIDPrevious && tenantIdCurrent !== this.tenantIDPrevious) {

            let removeTenantGroup = false;
            if (this.selectedProperty.tenantEmail !== this.tenantEmailPrevious) {
              removeTenantGroup = true;
            }

            // remove old tenantID
            this.removeFromTenantTable(removeTenantGroup);
          }

          // add to Tenant's favorite when landlord add to property binder tenantID
          // Calling API but lambda not getting executed
          if (this.selectedProperty.tenantID) {
            setTimeout(() => {
              let tenantTempProperty: any = {
                customerId: this.selectedProperty.tenantID,
                address: this.selectedProperty.address,
                tenantName: this.selectedProperty.tenantName,
                tenantEmail: this.selectedProperty.tenantEmail,
                tenantPhone: this.selectedProperty.tenantPhone,
                mlsId: this.selectedProperty.mlsId,
              };

              let tenantFavProperty: any = JSON.parse(JSON.stringify(tenantTempProperty));
              // tenantFavProperty.customerId = tenantFavProperty.tenantID; // populated above
              this.httpClient.put(this.url, tenantFavProperty, { headers: this.httpHeaders, observe: 'response' }).subscribe(
                (data: any) => {
                  console.log(data)
                },
                (error) => {
                  console.log(error);
                }
              )
            }, 500)
          }

          if (this.selectedProperty.tenantEmail) {
            let tenantName = this.selectedProperty.tenantName.split(' ');
            let tenantFirstName = tenantName[0]
            let tenantLastName = tenantName[1]
            let phone = `${this.selectedProperty.tenantPhone.area}-${this.selectedProperty.tenantPhone.exchange}-${this.selectedProperty.tenantPhone.subscriber}`

            let subscriberData = {
              firstName: tenantFirstName,
              lastName: tenantLastName,
              phone: phone,
              email: this.selectedProperty.tenantEmail,
              notes: this.selectedProperty.notes,
              address: this.selectedProperty.address,
              community: this.selectedProperty.community,
              isTenant: 'True',
            }

            this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
            this.httpClient.put(this.subscribersUrl, subscriberData, { headers: this.httpHeaders, observe: 'response' }).subscribe(
              (data: any) => {
                console.log('Added subscriber.')
                console.log(data)
              }
            )
          }

          setTimeout(() => {
            this.selectedProperty = null;
            this.newProperty = false;
            this.getProperties();

            this.repairItem = {
              repairItem: null,
              repairCost: null,
              repairDate: null,
              repairNotes: null,
            }
          }, 1500)
        },
        (error) => {
          console.log(error);
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  cancelProperty() {
    this.selectedProperty = null;
    this.newProperty = false;
  }

  displayAddress(address: any) {
    return address.split('_').join(' ');
  }

  displayAddressOnlyNumber(address: any) {
    return address.split('_')[0];
  }

  getDisplayAddress(address: string) {
    let addr = address.split('-')[0];
    let addrSplitted = addr.split('__');
    return addrSplitted[0] + '\n' + addrSplitted[1];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      // data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        let url = this.url + this.selectedProperty.address;

        try {
          this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
          this.httpClient.delete(url, { headers: this.httpHeaders, observe: 'response' }).subscribe(
            (data: any) => {
              console.log(data)
              this.selectedProperty = null;
              this.newProperty = false;
              this.getProperties()
            },
            (error) => {
              console.log(error);
            }
          );
        }
        catch (e) {
          console.log(e);
        }
      }
    });
  }

  disabled() {
    if (!this.selectedProperty.address) {
      return true;
    }
    return false;
  }

  addRepairItem() {
    // this.selectedProperty.repairs.push({
    //   repairDate: this.repairItem.repairDate,
    //   repairItem: this.repairItem.repairItem,
    //   repairCost: this.repairItem.repairCost,
    //   repairNotes: this.repairItem.repairNotes,
    // })

    // this.sortedRepairs = this.selectedProperty.repairs.slice();

    this.sortedRepairs.push({
      repairDate: this.repairItem.repairDate,
      repairItem: this.repairItem.repairItem,
      repairCost: this.repairItem.repairCost,
      repairNotes: this.repairItem.repairNotes,
    })

    this.selectedProperty.repairs = this.sortedRepairs;

    this.repairItem = {
      repairItem: null,
      repairCost: null,
      repairDate: null,
      repairNotes: null,
    }
  }

  removeRepairItem(index: number) {
    this.openDialogRemoveRepairItem(index);
  }

  openDialogRemoveRepairItem(index: any) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      // data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        this.sortedRepairs.splice(index, 1);
      }
    })
  }

  showDate(item: any) {
    return item.repairDate ? new Date(item.repairDate).toDateString() : null;
  }

  sortData(sort: Sort) {
    const data = this.selectedProperty.repairs.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedRepairs = data;
      return;
    }

    this.sortedRepairs = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.compare(a.repairDate, b.repairDate, isAsc);
        case 'item':
          return this.compare(a.repairItem, b.repairItem, isAsc);
        case 'cost':
          return this.compare(a.repairCost, b.repairCost, isAsc);
        case 'notes':
          return this.compare(a.repairNotes, b.repairNotes, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}

/** Data structure for holding telephone number. */
export class MyTel {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string,
  ) { }
}


/** Custom `MatFormFieldControl` for telephone number input. */
@Component({
  selector: 'example-tel-input',
  templateUrl: '../contact-us/example-tel-input-example.html',
  styleUrl: '../contact-us/example-tel-input-example.css',
  providers: [{ provide: MatFormFieldControl, useExisting: MyTelInput }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class MyTelInput implements ControlValueAccessor, MatFormFieldControl<MyTel>, OnDestroy {
  static nextId = 0;
  @ViewChild('area') areaInput: any;
  @ViewChild('exchange') exchangeInput: any;
  @ViewChild('subscriber') subscriberInput: any;

  parts: FormGroup<{
    area: FormControl<string | null>;
    exchange: FormControl<string | null>;
    subscriber: FormControl<string | null>;
  }>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'example-tel-input';
  id = `example-tel-input-${MyTelInput.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): MyTel | null {
    if (this.parts.valid) {
      const {
        value: { area, exchange, subscriber },
      } = this.parts;
      return new MyTel(area!, exchange!, subscriber!);
    }
    return null;
  }
  set value(tel: MyTel | null) {
    const { area, exchange, subscriber } = tel || new MyTel('', '', '');
    this.parts.setValue({ area, exchange, subscriber });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.userAriaDescribedBy = '';
    this._placeholder = '';

    this.parts = formBuilder.group({
      area: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      exchange: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      subscriber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")]],
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.example-tel-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.subscriber.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.exchange.valid) {
      this._focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.area.valid) {
      this._focusMonitor.focusVia(this.exchangeInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.areaInput, 'program');
    }
  }

  writeValue(tel: MyTel | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}