<div class="d-flex justify-content-between align-items-center login-header mb-3">
    <div *ngIf="appService.isMobile" class="login-title">Properties</div>

    <div class="top-buttons-wrapper">

        <!-- Display user when log in using email/pw  -->
        <span>{{ user?.signInDetails?.loginId }}</span>

        <span *ngIf="!appService.isMobile" class="login-title">Properties</span>
        <button mat-flat-button (click)="authService.signOut()"
            class="d-flex justify-content-center button-logout text-uppercase">
            signout</button>
    </div>
</div>

<div *ngIf="!selectedProperty && !newProperty"
    class="d-flex flex-column justify-content-center align-items-center fav-property-outter-wrapper">

    <!-- <app-user></app-user> -->

    <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/my-profile'" style="cursor: pointer; color: blue">My
                    Profile</a></li>
            <li class="breadcrumb-item active" aria-current="page">Property Binder</li>
        </ol>
    </nav>

    <mat-tab-group class="mt-3 mat-tab-group " [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="PropertyInfo">
            <div class="d-flex flex-column align-items-center">
                <div *ngFor="let p of properties" class="mt-3 d-flex justify-content-center">
                    <div class="d-flex justify-content-center property-wrapper">
                        <a (click)="selectProperty(p)">{{getDisplayAddress(p.address)}}</a>
                    </div>
                </div>
                <div class="d-flex justify-content-center property-new-property text-uppercase">
                    <a (click)="createNewProperty()">add property</a>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="WorkOrder">
            <app-work-order class="mt-3"></app-work-order>
        </mat-tab>
    </mat-tab-group>
</div>

<div *ngIf="selectedProperty || newProperty" class="d-flex flex-column justify-content-start align-items-center">
    <!-- <div class="d-flex flex-column justify-content-start align-items-center"> -->

    <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="routePropertiesMain()"
                    style="cursor: pointer; color: blue">Properties</a></li>
            <li class="breadcrumb-item active" aria-current="page">Selected Property</li>
        </ol>
    </nav>

    <div *ngIf="!newProperty" class="property-title mt-3">{{ displayAddress(selectedProperty.address) }}
    </div>

    <div *ngIf="newProperty" class="item-wrapper mt-3">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>{{ "Address" | translate }} </mat-label>
            <input matInput placeholder="" rows="1" [(ngModel)]="selectedProperty.address" required>
            <mat-hint>{{ "Address" | translate }}</mat-hint>
            <!-- <mat-error
                        *ngIf="contactUsFormGroup.controls['firstName'].invalid
                        && (contactUsFormGroup.controls['firstName'].dirty || contactUsFormGroup.controls['firstName'].touched)">{{getErrorMessage()}}</mat-error> -->
            <!-- <mat-error *ngIf="contactUsFormGroup.controls['address'].invalid">{{getErrorMessage()}}</mat-error> -->
        </mat-form-field>
    </div>

    <div class="accordion mt-3 item-wrapper" id="workOrderComponentId">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
                    Property Information
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="item-wrapper">
                        <mat-form-field class="w-100 mt-2" appearance="fill">
                            <mat-label>{{ "Owner" | translate }} </mat-label>
                            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.owner"
                                (input)="userInput('owner')" maxLength="{{ max_char_owner}}"></textarea>
                            <mat-hint>{{ "Owner" | translate }}</mat-hint>
                        </mat-form-field>
                        <div *ngIf="reached_max_char_owner" class="max-char-warning">Maximum characters reached.</div>
                    </div>

                    <div class="item-wrapper">
                        <mat-form-field class="w-100 mt-2" appearance="fill">
                            <mat-label>{{ "Community" | translate }}</mat-label>
                            <mat-select [(ngModel)]="selectedProperty.community" required>
                                <mat-option *ngFor="let community of appService.communities" [value]="community">{{
                                    community
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="item-wrapper wide-section-wrapper">
                        <div class="d-flex w-100 wide-section-wrapper-inner">
                            <div class="d-flex flex-column wide-section-inner">
                                <mat-form-field class="mt-2" appearance="fill">
                                    <mat-label>{{ "HOA Name (Master)" | translate }} </mat-label>
                                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoaName"
                                        (input)="userInput('hoaNameMaster')" maxLength="{{ max_char_hoaNameMaster }}">
                                    <mat-hint>{{ "HOA Name (Master)" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaNameMaster" class="max-char-warning-stacked">Maximum
                                    characters
                                    reached.
                                </div>
                            </div>

                            <div class="d-flex flex-column wide-section-inner">
                                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                                    <mat-label>{{ "HOA Manager (Master)" | translate }} </mat-label>
                                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoaMgrName"
                                        (input)="userInput('hoaManagerMaster')"
                                        maxLength="{{ max_char_hoaManagerMaster }}">
                                    <mat-hint>{{ "HOA Manager (Master)" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaManagerMaster" class="max-char-warning-stacked">Maximum
                                    characters
                                    reached.</div>
                            </div>
                        </div>

                        <div class="d-flex w-100">
                            <div class="d-flex flex-column input-small-wrapper">
                                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                                    <mat-label>{{ "Cost $" | translate }} </mat-label>
                                    <input matInput type="number" placeholder="" [(ngModel)]="selectedProperty.hoaCost"
                                        (input)="userInput('hoaCostMaster')" min="0" max="{{ max_char_hoaCostMaster }}">
                                    <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaCostMaster" class="max-char-warning-stacked">Maximum
                                    number
                                    reached.</div>
                            </div>

                            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                                <mat-label>Type</mat-label>
                                <mat-select [(ngModel)]="selectedProperty.hoaDraftType">
                                    <mat-option value="auto">Auto</mat-option>
                                    <mat-option value="cc">Credit Card</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                                <mat-label>Frequency</mat-label>
                                <mat-select [(ngModel)]="selectedProperty.hoaDraftFreq">
                                    <mat-option value="monthly">Monthly</mat-option>
                                    <mat-option value="quarterly">Quarterly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="item-wrapper">
                        <div class="d-flex position-relative">
                            <mat-form-field class="w-100 mt-2">
                                <mat-label>{{ "HOA Email (Master)" | translate }}</mat-label>
                                <input matInput type="email" placeholder="email@example.com"
                                    [(ngModel)]="selectedProperty.hoaEmail" (input)="userInput('hoaEmailMaster')"
                                    maxLength="{{ max_char_hoaEmailMaster }}">
                                <mat-hint>{{ "HOA Email (Master)" | translate }}</mat-hint>
                                @if (email.invalid) {
                                <mat-error>{{getErrorMessage()}}</mat-error>
                                }
                            </mat-form-field>
                            <a class="shortcut-icon" href="mailto:{{selectedProperty.hoaEmail}}"><img
                                    src="assets/images/email-circled.png" width="35px" height="35px" /></a>
                        </div>
                        <div *ngIf="reached_max_char_hoaEmailMaster" class="max-char-warning">Maximum characters
                            reached.</div>
                    </div>


                    <div class="item-wrapper d-flex position-relative">
                        <mat-form-field class="w-100 mt-2">
                            <mat-label>{{ "HOA Phone (Master)" | translate }}</mat-label>
                            <example-tel-input [(ngModel)]="selectedProperty.hoaPhone"></example-tel-input>
                            <mat-hint>{{ "Include area code" | translate }}</mat-hint>
                        </mat-form-field>
                        <a class="shortcut-icon" href="tel:{{selectedProperty.hoaPhone}}"><img
                                src="assets/images/phone-circled2.png" width="35px" height="35px" /></a>
                    </div>

                    <div class="item-wrapper wide-section-wrapper">
                        <div class="d-flex w-100 wide-section-wrapper-inner">
                            <div class="d-flex flex-column wide-section-inner">
                                <mat-form-field class="mt-2" appearance="fill">
                                    <mat-label>{{ "HOA Name (Second)" | translate }} </mat-label>
                                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoa2ndName"
                                        (input)="userInput('hoaNameSecond')" maxLength="{{ max_char_hoaNameMaster }}">
                                    <mat-hint>{{ "HOA Name (Second)" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaNameSecond" class="max-char-warning-stacked">Maximum
                                    characters
                                    reached.
                                </div>
                            </div>

                            <div class="d-flex flex-column wide-section-inner">
                                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                                    <mat-label>{{ "HOA Manager (Second)" | translate }} </mat-label>
                                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoa2ndMgrName"
                                        (input)="userInput('hoaManagerSecond')"
                                        maxLength="{{ max_char_hoaManagerMaster }}">
                                    <mat-hint>{{ "HOA Manager (Second)" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaManagerSecond" class="max-char-warning-stacked">Maximum
                                    characters
                                    reached.
                                </div>
                            </div>
                        </div>

                        <div class="d-flex w-100">
                            <div class="d-flex flex-column input-small-wrapper">
                                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                                    <mat-label>{{ "Cost $" | translate }} </mat-label>
                                    <input matInput type="number" placeholder=""
                                        [(ngModel)]="selectedProperty.hoa2ndCost" (input)="userInput('hoaCostSecond')"
                                        maxLength="{{ max_char_hoaCostMaster }}" min="0"
                                        max="{{ max_char_hoaCostMaster }}">
                                    <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                                </mat-form-field>
                                <div *ngIf="reached_max_char_hoaCostSecond" class="max-char-warning-stacked">Maximum
                                    number
                                    reached.</div>
                            </div>

                            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                                <mat-label>Type</mat-label>
                                <mat-select [(ngModel)]="selectedProperty.hoa2ndDraftType">
                                    <mat-option value="auto">Auto</mat-option>
                                    <mat-option value="cc">Credit Card</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                                <mat-label>Frequency</mat-label>
                                <mat-select [(ngModel)]="selectedProperty.hoa2ndDraftFreq">
                                    <mat-option value="monthly">Monthly</mat-option>
                                    <mat-option value="quarterly">Quarterly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="item-wrapper">
                        <div class="d-flex position-relative">
                            <mat-form-field class="w-100 mt-2">
                                <mat-label>{{ "HOA Email (Second)" | translate }}</mat-label>
                                <input matInput type="email" placeholder="email@example.com"
                                    [(ngModel)]="selectedProperty.hoa2ndEmail" (input)="userInput('hoaEmailSecond')"
                                    maxLength="{{ max_char_hoaEmailMaster }}">
                                <mat-hint>{{ "HOA Email (Second)" | translate }}</mat-hint>
                                @if (email.invalid) {
                                <mat-error>{{getErrorMessage()}}</mat-error>
                                }
                            </mat-form-field>
                            <a class="shortcut-icon" href="mailto:{{selectedProperty.hoa2ndEmail}}"><img
                                    src="assets/images/email-circled.png" width="35px" height="35px" /></a>
                        </div>
                        <div *ngIf="reached_max_char_hoaEmailSecond" class="max-char-warning">Maximum characters
                            reached.</div>
                    </div>


                    <div class="item-wrapper d-flex position-relative">
                        <mat-form-field class="w-100 mt-2">
                            <mat-label>{{ "HOA Phone (Second)" | translate }}</mat-label>
                            <example-tel-input [(ngModel)]="selectedProperty.hoa2ndPhone"></example-tel-input>
                            <mat-hint>{{ "Include area code" | translate }}</mat-hint>
                        </mat-form-field>
                        <a class="shortcut-icon" href="tel:{{selectedProperty.hoa2ndPhone}}"><img
                                src="assets/images/phone-circled2.png" width="35px" height="35px" /></a>
                    </div>

                    <div class="item-wrapper">
                        <mat-form-field class="w-100 mt-2" appearance="fill">
                            <mat-label>{{ "Property Tax" | translate }} </mat-label>
                            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.propertyTax"
                                (input)="userInput('propertyTax')" maxLength="{{ max_char_propertyTax }}"></textarea>
                            <mat-hint>{{ "Property Tax" | translate }}</mat-hint>
                        </mat-form-field>
                        <div *ngIf="reached_max_char_propertyTax" class="max-char-warning">Maximum characters
                            reached.</div>
                    </div>

                    <div class="item-wrapper">
                        <mat-form-field class="w-100 mt-2" appearance="fill">
                            <mat-label>{{ "Insurance" | translate }} </mat-label>
                            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.insurance"
                                (input)="userInput('insurance')" maxLength="{{ max_char_propertyTax }}"></textarea>
                            <mat-hint>{{ "Insurance" | translate }}</mat-hint>
                        </mat-form-field>
                        <div *ngIf="reached_max_char_insurance" class="max-char-warning">Maximum characters
                            reached.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Owner" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.owner" (input)="userInput('owner')"
                maxLength="{{ max_char_owner}}"></textarea>
            <mat-hint>{{ "Owner" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_owner" class="max-char-warning">Maximum characters reached.</div>
    </div>

    <div class="item-wrapper wide-section-wrapper">
        <div class="d-flex w-100 wide-section-wrapper-inner">
            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2" appearance="fill">
                    <mat-label>{{ "HOA Name (Master)" | translate }} </mat-label>
                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoaName"
                        (input)="userInput('hoaNameMaster')" maxLength="{{ max_char_hoaNameMaster }}">
                    <mat-hint>{{ "HOA Name (Master)" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaNameMaster" class="max-char-warning-stacked">Maximum characters
                    reached.
                </div>
            </div>

            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                    <mat-label>{{ "HOA Manager (Master)" | translate }} </mat-label>
                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoaMgrName"
                        (input)="userInput('hoaManagerMaster')" maxLength="{{ max_char_hoaManagerMaster }}">
                    <mat-hint>{{ "HOA Manager (Master)" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaManagerMaster" class="max-char-warning-stacked">Maximum
                    characters
                    reached.</div>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="d-flex flex-column input-small-wrapper">
                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                    <mat-label>{{ "Cost $" | translate }} </mat-label>
                    <input matInput type="number" placeholder="" [(ngModel)]="selectedProperty.hoaCost"
                        (input)="userInput('hoaCostMaster')" min="0" max="{{ max_char_hoaCostMaster }}">
                    <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaCostMaster" class="max-char-warning-stacked">Maximum number
                    reached.</div>
            </div>

            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="selectedProperty.hoaDraftType">
                    <mat-option value="auto">Auto</mat-option>
                    <mat-option value="cc">Credit Card</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                <mat-label>Frequency</mat-label>
                <mat-select [(ngModel)]="selectedProperty.hoaDraftFreq">
                    <mat-option value="monthly">Monthly</mat-option>
                    <mat-option value="quarterly">Quarterly</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="item-wrapper">
        <div class="d-flex position-relative">
            <mat-form-field class="w-100 mt-2">
                <mat-label>{{ "HOA Email (Master)" | translate }}</mat-label>
                <input matInput type="email" placeholder="email@example.com" [(ngModel)]="selectedProperty.hoaEmail"
                    (input)="userInput('hoaEmailMaster')" maxLength="{{ max_char_hoaEmailMaster }}">
                <mat-hint>{{ "HOA Email (Master)" | translate }}</mat-hint>
                @if (email.invalid) {
                <mat-error>{{getErrorMessage()}}</mat-error>
                }
            </mat-form-field>
            <a class="shortcut-icon" href="mailto:{{selectedProperty.hoaEmail}}"><img
                    src="assets/images/email-circled.png" width="35px" height="35px" /></a>
        </div>
        <div *ngIf="reached_max_char_hoaEmailMaster" class="max-char-warning">Maximum characters reached.</div>
    </div>


    <div class="item-wrapper d-flex position-relative">
        <mat-form-field class="w-100 mt-2">
            <mat-label>{{ "HOA Phone (Master)" | translate }}</mat-label>
            <example-tel-input [(ngModel)]="selectedProperty.hoaPhone"></example-tel-input>
            <mat-hint>{{ "Include area code" | translate }}</mat-hint>
        </mat-form-field>
        <a class="shortcut-icon" href="tel:{{selectedProperty.hoaPhone}}"><img src="assets/images/phone-circled2.png"
                width="35px" height="35px" /></a>
    </div>

    <div class="item-wrapper wide-section-wrapper">
        <div class="d-flex w-100 wide-section-wrapper-inner">
            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2" appearance="fill">
                    <mat-label>{{ "HOA Name (Second)" | translate }} </mat-label>
                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoa2ndName"
                        (input)="userInput('hoaNameSecond')" maxLength="{{ max_char_hoaNameMaster }}">
                    <mat-hint>{{ "HOA Name (Second)" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaNameSecond" class="max-char-warning-stacked">Maximum characters
                    reached.
                </div>
            </div>

            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                    <mat-label>{{ "HOA Manager (Second)" | translate }} </mat-label>
                    <input matInput placeholder="" [(ngModel)]="selectedProperty.hoa2ndMgrName"
                        (input)="userInput('hoaManagerSecond')" maxLength="{{ max_char_hoaManagerMaster }}">
                    <mat-hint>{{ "HOA Manager (Second)" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaManagerSecond" class="max-char-warning-stacked">Maximum
                    characters
                    reached.
                </div>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="d-flex flex-column input-small-wrapper">
                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                    <mat-label>{{ "Cost $" | translate }} </mat-label>
                    <input matInput type="number" placeholder="" [(ngModel)]="selectedProperty.hoa2ndCost"
                        (input)="userInput('hoaCostSecond')" maxLength="{{ max_char_hoaCostMaster }}" min="0"
                        max="{{ max_char_hoaCostMaster }}">
                    <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_hoaCostSecond" class="max-char-warning-stacked">Maximum number
                    reached.</div>
            </div>

            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="selectedProperty.hoa2ndDraftType">
                    <mat-option value="auto">Auto</mat-option>
                    <mat-option value="cc">Credit Card</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="input-small-wrapper mt-2 ml-2" appearance="fill">
                <mat-label>Frequency</mat-label>
                <mat-select [(ngModel)]="selectedProperty.hoa2ndDraftFreq">
                    <mat-option value="monthly">Monthly</mat-option>
                    <mat-option value="quarterly">Quarterly</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="item-wrapper">
        <div class="d-flex position-relative">
            <mat-form-field class="w-100 mt-2">
                <mat-label>{{ "HOA Email (Second)" | translate }}</mat-label>
                <input matInput type="email" placeholder="email@example.com" [(ngModel)]="selectedProperty.hoa2ndEmail"
                    (input)="userInput('hoaEmailSecond')" maxLength="{{ max_char_hoaEmailMaster }}">
                <mat-hint>{{ "HOA Email (Second)" | translate }}</mat-hint>
                @if (email.invalid) {
                <mat-error>{{getErrorMessage()}}</mat-error>
                }
            </mat-form-field>
            <a class="shortcut-icon" href="mailto:{{selectedProperty.hoa2ndEmail}}"><img
                    src="assets/images/email-circled.png" width="35px" height="35px" /></a>
        </div>
        <div *ngIf="reached_max_char_hoaEmailSecond" class="max-char-warning">Maximum characters reached.</div>
    </div>


    <div class="item-wrapper d-flex position-relative">
        <mat-form-field class="w-100 mt-2">
            <mat-label>{{ "HOA Phone (Second)" | translate }}</mat-label>
            <example-tel-input [(ngModel)]="selectedProperty.hoa2ndPhone"></example-tel-input>
            <mat-hint>{{ "Include area code" | translate }}</mat-hint>
        </mat-form-field>
        <a class="shortcut-icon" href="tel:{{selectedProperty.hoa2ndPhone}}"><img src="assets/images/phone-circled2.png"
                width="35px" height="35px" /></a>
    </div> -->

    <!-- GROUP OLD CODE START -->
    <!-- <div class="item-wrapper wide-section-wrapper show-border">
        <div class="d-flex w-100 wide-section-wrapper-inner">
            <mat-form-field class="wide-section-inner mt-2" appearance="fill">
                <mat-label>Repair Item</mat-label>
                <mat-select [(ngModel)]="repairItem.repairItem">
                    <mat-option value="refrigerator">Refrigerator</mat-option>
                    <mat-option value="range">Range</mat-option>
                    <mat-option value="range hood">Range Hood</mat-option>
                    <mat-option value="dish washer">Dish Washer</mat-option>
                    <mat-option value="oven">Oven</mat-option>
                    <mat-option value="fire place">Fire Place</mat-option>
                    <mat-option value="hvac">HVAC</mat-option>
                    <mat-option value="toilet">Toilet</mat-option>
                    <mat-option value="sink">Sink</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100 mt-2 small-x-gap-lg" appearance="fill">
                <mat-label>Repair Date</mat-label>
                <input matInput [matDatepicker]="repairPicker" [(ngModel)]="repairItem.repairDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="repairPicker"></mat-datepicker-toggle>
                <mat-datepicker #repairPicker></mat-datepicker>
            </mat-form-field>

            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2 small-x-gap-lg full-height" appearance="fill">
                    <mat-label>{{ "Cost $" | translate }} </mat-label>
                    <input matInput type="number" placeholder="" [(ngModel)]="repairItem.repairCost"
                        (input)="userInput('repairCost')" maxLength="{{ max_char_hoaCostMaster }}" min="0" max="99999">
                    <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_repairCost" class="max-char-warning-stacked">Maximum number
                    reached.</div>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="d-flex flex-column wide-section-inner">
                <mat-form-field class="mt-2 small-x-gap-lg" appearance="fill">
                    <mat-label>{{ "Repair Notes" | translate }} </mat-label>
                    <textarea matInput placeholder="" [(ngModel)]="repairItem.repairNotes"
                        (input)="userInput('repairNotes')" maxLength="{{ max_char_repairNotes }}"></textarea>
                    <mat-hint>{{ "Repair Notes" | translate }}</mat-hint>
                </mat-form-field>
                <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number
                    reached.</div>
            </div>

            <fa-icon (click)="addRepairItem()" [icon]="faPlusSquare" class="add-repair-plus"></fa-icon>
        </div>
    </div> -->

    <!-- <table matSort (matSortChange)="sortData($event)" class="repair-table-wrapper">
        <tr>
            <th mat-sort-header="date">Date</th>
            <th mat-sort-header="item">Item</th>
            <th mat-sort-header="cost">Cost($)</th>
            <th mat-sort-header="notes">Notes</th>
        </tr>

        <tr *ngFor="let repair of sortedRepairs; let i = index" class="repair-table-row">
            <td style="width: 10%">{{showDate(repair)}}</td>
            <td style="width: 10%">{{repair?.repairItem}}</td>
            <td style="width: 10%">{{repair?.repairCost}}</td>
            <td class="repair-notes">{{repair?.repairNotes}}</td>
            <fa-icon (click)="removeRepairItem(i)" [icon]="faTrashCan" class="delete-repair-plus"></fa-icon>
        </tr>

    </table> -->

    <!-- <div *ngFor="let repair of selectedProperty.repairs" class="repair-wrapper">
        <div class="repair-item-wrapper">
            <span>{{repair.repairDate.toLocaleString()}}</span>
            <span class="ml-3">{{repair.repairItem}}</span>
            <span class="ml-3">${{repair.repairCost}}</span>
            <span class="ml-3">{{repair.repairNotes}}</span>
        </div>
    </div> -->

    <!-- <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Repair History" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.repairHistory"></textarea>
            <mat-hint>{{ "Repair History" | translate }}</mat-hint>
        </mat-form-field>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Warranty" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.warranty"></textarea>
            <mat-hint>{{ "Warranty" | translate }}</mat-hint>
        </mat-form-field>
    </div> -->
    <!-- GROUP OLD CODE END -->

    <!-- <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Property Tax" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.propertyTax"
                (input)="userInput('propertyTax')" maxLength="{{ max_char_propertyTax }}"></textarea>
            <mat-hint>{{ "Property Tax" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_propertyTax" class="max-char-warning">Maximum characters
            reached.</div>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Insurance" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.insurance" (input)="userInput('insurance')"
                maxLength="{{ max_char_propertyTax }}"></textarea>
            <mat-hint>{{ "Insurance" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_insurance" class="max-char-warning">Maximum characters
            reached.</div>
    </div> -->

    <div class="tenant-info-divider">
        Tenant Info
    </div>

    <div class="item-wrapper">
        <div class="d-flex position-relative">
            <mat-form-field class="w-100 mt-2" appearance="fill">
                <mat-label>{{ "Tenant ID" | translate }} </mat-label>
                <input matInput placeholder="" [(ngModel)]="selectedProperty.tenantID" (input)="userInput('tenantID')"
                    maxLength="{{ max_char_tenantID }}">
                <mat-hint>{{ "Tenant ID" | translate }}</mat-hint>
            </mat-form-field>
            <fa-icon (click)="addUser()" [icon]="faPlusSquare" style="font-size: 2rem"
                class="shortcut-icon cursor-pointer"></fa-icon>
        </div>
        <div *ngIf="reached_max_char_tenantID" class="max-char-warning">Maximum characters
            reached.</div>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Monthly Rent" | translate }} </mat-label>
            <input matInput type="number" placeholder="Per Month" [(ngModel)]="selectedProperty.rent"
                (input)="userInput('rent')" maxLength="{{ max_char_rent }}" min="0" max="{{max_char_rent}}">
            <mat-hint>{{ "Monthly Rent" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_rent" class="max-char-warning">Maximum characters
            reached.</div>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Tenant Name" | translate }} </mat-label>
            <input matInput placeholder="" [(ngModel)]="selectedProperty.tenantName" (input)="userInput('tenantName')"
                maxLength="{{ max_char_tenantName }}">
            <mat-hint>{{ "Tenant Name(Tenant Viewable)" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_tenantName" class="max-char-warning">Maximum characters
            reached.</div>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>Lease Start Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="selectedProperty.leaseStart">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>Lease End Date</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]="selectedProperty.leaseEnd">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="item-wrapper">
        <div class="d-flex position-relative">
            <mat-form-field class="w-100 mt-2">
                <mat-label>{{ "Tenant Email" | translate }}</mat-label>
                <input matInput type="email" placeholder="email@example.com" [(ngModel)]="selectedProperty.tenantEmail"
                    (input)="userInput('tenantEmail')" maxLength="{{ max_char_hoaEmailMaster }}">
                <mat-hint>{{ "Tenant Email(Tenant Viewable)" | translate }}</mat-hint>
                @if (email.invalid) {
                <mat-error>{{getErrorMessage()}}</mat-error>
                }
            </mat-form-field>
            <a class="shortcut-icon" href="mailto:{{selectedProperty.tenantEmail}}"><img
                    src="assets/images/email-circled.png" width="35px" height="35px" /></a>
        </div>
        <div *ngIf="reached_max_char_tenantEmail" class="max-char-warning">Maximum characters reached.</div>
    </div>


    <div class="item-wrapper d-flex position-relative">
        <mat-form-field class="w-100 mt-2">
            <mat-label>{{ "Tenant Phone" | translate }}</mat-label>
            <example-tel-input [(ngModel)]="selectedProperty.tenantPhone"></example-tel-input>
            <mat-hint>{{ "Include area code(Tenant Viewable)" | translate }}</mat-hint>
        </mat-form-field>
        <a class="shortcut-icon" href="tel:{{selectedProperty.tenantPhone}}"><img src="assets/images/phone-circled2.png"
                width="35" height="35px" /></a>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Pet" | translate }} </mat-label>
            <input matInput placeholder="" [(ngModel)]="selectedProperty.pet" (input)="userInput('pet')"
                maxLength="{{ max_char_pet }}">
            <mat-hint>{{ "Pet" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_pet" class="max-char-warning">Maximum characters reached.</div>
    </div>

    <div class="item-wrapper">
        <mat-form-field class="w-100 mt-2" appearance="fill">
            <mat-label>{{ "Notes" | translate }} </mat-label>
            <textarea matInput placeholder="" [(ngModel)]="selectedProperty.note" (input)="userInput('notes')"
                maxLength="{{ max_char_notes }}"></textarea>
            <mat-hint>{{ "Notes" | translate }}</mat-hint>
        </mat-form-field>
        <div *ngIf="reached_max_char_notes" class="max-char-warning">Maximum characters reached.</div>
    </div>




    <div class="bottom-buttons-wrapper">
        <button mat-flat-button (click)="cancelProperty()"
            class="d-flex justify-content-center button-default text-uppercase ml-4 mt-2">
            Cancel</button>

        <button *ngIf="!newProperty" mat-flat-button (click)="deleteProperty()"
            class="d-flex justify-content-center button-default text-uppercase danger ml-4  mt-2">
            Delete</button>

        <button mat-flat-button (click)="saveProperty()" [disabled]="disabled()"
            class="mr-3 d-flex justify-content-center button-default text-uppercase ml-4 mt-2">
            Save</button>
    </div>
</div>