import {
  Component, OnInit, OnDestroy,
  ElementRef,
  QueryList,
  Inject,
  Input,
  Optional,
  Self,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit,
  ChangeDetectionStrategy,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet, Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgxEditorModule } from 'ngx-editor';
import { Validators, Editor, Toolbar, toDoc, toHTML } from 'ngx-editor';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  // Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ENUMS } from '../../models/constants';
import { WorkOrderFilesViewerComponent } from '../work-order-files-viewer/work-order-files-viewer.component';
import { environment } from '../../env/environment';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { AuthService } from '../services/auth.service';
import { AppServiceService } from '../services/app-service.service';
import { interval, Subject, Subscription, Observable, forkJoin, lastValueFrom } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { filter } from 'rxjs/operators';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    CommonModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FontAwesomeModule,
    ContactUsComponent,
    FooterComponent,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent {

  email;
  tempPassword;
  url;
  session;
  subId;
  delete = true;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService,
    public appService: AppServiceService,
    private router: Router,
    private location: Location,
    public dialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.url = `${environment.rootUrl}prod/users/`;

    this.appService.checkMobile();
  }

  async ngOnInit() {
    this.session = await this.authService.getCurrentSession();

    // Test
    // this.createUser();
    this.email = 'desong.e.yu+1@gmail.com';
    this.tempPassword = 'Abcd1234!';
  }

  createUser() {
    let data = {
      email: this.email,
      tempPassword: this.tempPassword,
      group: 'TenantGroup',
    }

    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)

    this.httpClient.post(this.url, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log(data.body)
        this.subId = data.body;

        let res = {
          subId: this.subId,
          email: this.email,
        }
        this.dialogRef.close(res);
      }, (error) => {
        console.log(JSON.stringify(error))

        this.httpClient.put(this.url, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
          (data: any) => {
            console.log(data.body)
            this.subId = data.body;

            let res = {
              subId: this.subId,
              email: this.email,
            }
            this.dialogRef.close(res);
          }, (error) => {
            console.log(JSON.stringify(error))
          }
        )
      }
    )

    // this.httpClient.put(this.url, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
    //   (data: any) => {
    //     console.log(data.body)
    //     this.subId = data.body;

    //     let res = {
    //       subId: this.subId,
    //       email: this.email,
    //     }
    //     this.dialogRef.close(res);
    //   }, (error) => {
    //     console.log(JSON.stringify(error))
    //   }
    // )
  }

  onOk() {
    this.createUser()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
